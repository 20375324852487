import React from 'react'
import "./footer.css"

const Footer = () => {
  return (
    <div className='footer'>
      <div className='footer-div'>Courtesy of <a href="https://www.datamining.informatik.uni-mainz.de/">The Data Mining Group, JGU Mainz, Germany.</a></div>
      <div className='footer-div'>Founding via <a href="https://curatime.org/">Curatime</a></div>
    </div>
  )
}

export default Footer