import React, { useState } from 'react';
import './instructions.css'
import instructionsData from '../../data/instructionsData';

  
const Instructions = () => {

  // Getting the current step for data display
  const [currentStep, setCurrentStep] = useState(0);

  // Going to the next step
  const handleNext = () => {
    if (currentStep < instructionsData.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  // Going to the previous step
  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <div className='box'> 
      <div className="inst-card"> 
        <div className='title-div'>
          <h2>Step {instructionsData[currentStep].step}: {instructionsData[currentStep].title}</h2> {/* Title format is 'Step x: x.title' */}
          <p>{instructionsData[currentStep].description}</p> {/* Current steps explanation */}
        </div>
        <img className='gif-img' src={instructionsData[currentStep].url} alt="" /> {/* Current steps gif */}
       
       
       {/* Next and prev buttons*/}
        <div className="button-div">
          <button className='button' onClick={handlePrevious} disabled={currentStep === 0}>
            Previous
          </button>
          <button className='button' onClick={handleNext} disabled={currentStep === instructionsData.length - 1}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Instructions;
