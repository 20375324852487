const quizData = [
    {
      question: "What is a sign of cardiac arrest?",
      options: [
        "The victim doesn't respond when stimulating her",
        "The victim loses consciousness",
        "The victim falls",
        "All of the above"
      ],
      correctAnswer: "All of the above"
    },
    {
      question: "What is a sign before a cardiac arrest?",
      options: [
        "High fever and shivering",
        "Prolonged chest pain extending to the arms",
        "Abdominal cramps",
        "Head ache"
      ],
      correctAnswer: "Prolonged chest pain extending to the arms"
    },
    {
      question: "What should be the first reflex to have when assisting to a cardial arrest?",
      options: [
        "Check the victim's identification",
        "Call the emergency services",
        "Give the victim water",
        "Try to move the victim"
      ],
      correctAnswer: "Call the emergency services"
    },
    {
      question: "What informations you should provide to the emergency service?",
      options: [
        "Describe the situation to the emergency service operator",
        "Tell him what has been already done",
        "Give him the nearest address possible",
        "All of the above"
      ],
      correctAnswer: "All of the above"
    },
    {
      question: "When performing CPR, where should you place the victim?",
      options: [
        "Hard surface",
        "Soft surface",
        "Does not matter",
        "Sterilized place"
      ],
      correctAnswer: "Hard surface"
    },
    {
      question: "When performing CPR, where should your hands be placed on the victim?",
      options: [
        "On the victim's stomach",
        "On the victim's throat",
        "In the middle of the chest, between the breasts",
        "On the victim's forehead"
      ],
      correctAnswer: "In the middle of the chest, between the breasts"
    },
    {
      question: "How deep should you press into the chest when performing CPR?",
      options: [
        "1-2 cm",
        "2-3 cm",
        "3-4 cm",
        "4-5 cm"
      ],
      correctAnswer: "3-4 cm"
    },
    {
      question: "How many time you should press when performing CPR before mouth to mouth?",
      options: [
        "10 times",
        "20 times",
        "30 times",
        "40 times"
      ],
      correctAnswer: "30 times"
    },
    {
      question: "What should you do after performing 30 chest compressions during CPR?",
      options: [
        "Take a break",
        "Perform two breaths or mouth-to-mouth",
        "Stop performing",
        "Call for help again"
      ],
      correctAnswer: "Perform two breaths or mouth-to-mouth"
    },
    {
      question: "How long does it take for brain damage to become irreversible without oxygen?",
      options: [
        "1 minute",
        "3 minutes",
        "5 minutes",
        "10 minutes"
      ],
      correctAnswer: "5 minutes"
    },
    {
      question: "Why is it important to perform chest compressions properly during CPR?",
      options: [
        "To keep the victim's body warm",
        "To circulate the blood and oxygenate the brain",
        "To prevent muscle cramps",
        "To wake the victim up"
      ],
      correctAnswer: "To circulate the blood and oxygenate the brain"
    },
    {
      question: "How much does a victim's chance of survival increase with each minute saved?",
      options: [
        "1%",
        "5%",
        "10%",
        "15%"
      ],
      correctAnswer: "10%"
    },
    {
      question: "What should you do if you are alone and find someone in cardiac arrest?",
      options: [
        "Perform CPR for 2 minutes before calling for help",
        "Call emergency services immediately and then start CPR",
        "Look for a defibrillator first",
        "Wait for other people"
      ],
      correctAnswer: "Call emergency services immediately and then start CPR"
    },
    {
      question: "Which of the following is NOT a recommended action during CPR?",
      options: [
        "Performing chest compressions on a hard surface",
        "Pressing down with your arms straight and whole body weight",
        "Performing compressions with only your hands and no body weight",
        "Giving breaths every 30 compressions"
      ],
      correctAnswer: "Performing compressions with only your hands and no body weight"
    },
  ];
  
  export default quizData;
  